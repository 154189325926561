<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ this.title }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text
                    v-if="loading"
                >

                    <v-col
                        class="text-subtitle-1 text-center"
                        cols="12"
                    >
                        {{ $t("Загрузка...") }}
                    </v-col>

                    <v-col cols="12">
                        <v-progress-linear
                            color="#a94442"
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                    </v-col>

                </v-card-text>
                <v-card-text
                    v-else
                    class="wrapperFormModal"
                >
                    <v-card 
                        flat
                    >
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="2">
                                    <label class="f-label">{{ $t("Поиск") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="10">
                                    <div class="text-field-with-icon">
                                        <v-text-field
                                            v-model="search"
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            append-icon="fas fa-search"
                                            clearable
                                            @click:clear="search = null"
                                        ></v-text-field>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card 
                        flat
                    >
                        <v-card-text>
                            <v-list class="modal-select-list" dense outlined>
                                <v-list-item-group
                                    v-if="nomenclatures.length > 0"
                                    v-model="nomenclature"
                                    color="cyan darken-1"
                                >
                                    <v-list-item
                                        v-for="(item, i) in nomenclatures"
                                        :key="i"
                                        :value="item"
                                        @dblclick="doubleSelect(item)"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title v-text="(''+item[1]).concat(' ', item[2])"></v-list-item-title>
                                        </v-list-item-content>

                                    </v-list-item>

                                </v-list-item-group>
                                <div v-else>
                                    <v-col cols="12" sm="12" md="12">
                                        <div class="onlyReadData">
                                            {{ $t("К_сожалению,_по_Вашему_запросу_ничего_не_найдено") }}
                                        </div>
                                    </v-col>
                                </div>

                            </v-list>
                        </v-card-text>
                    </v-card>
              
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        color="cyan" 
                        text
                        depressed 
                        @click="ok" 
                        v-if="valid"
                    >
                        {{ $t("Выбрать") }}
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{ $t("Отмена") }}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n'
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "SelectNomenclatureDlg",
    data () {
        return {
            title: i18n.t("Номенклатура"),
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            }
        }
    },
    computed: {
        ...mapGetters('global/dialogs/selectNomenclature',
        { 
            visible: 'isVisible',
            loading: 'isLoading',
            valid: 'isValid',
            nomenclatures: 'getNomenclatures'
        }),
        nomenclature: {
            get: function() {
                return this.$store.getters['global/dialogs/selectNomenclature/getNomenclature'];
            },
            set: function(v) {
                this.$store.commit('global/dialogs/selectNomenclature/SET_NOMENCLATURE', v); 
            }
        },
        search: {
            get: function() {
                return this.$store.getters['global/dialogs/selectNomenclature/getSearch'];
            },
            set: function(v) {
                this.$store.commit('dialogs/selectNomenclature/SET_SEARCH', v); 
            }
        }
    },
    methods: {
        ...mapActions('global/dialogs/selectNomenclature', ['ok', 'cancel']),
        doubleSelect(item) {
            this.$store.commit('global/dialogs/selectNomenclature/SET_NOMENCLATURE', item);
            this.ok();
        }
    }
}
</script>